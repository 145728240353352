import { addTouchStartListener } from "./swipe-back"
addTouchStartListener()

declare global {
  interface Document {
    flutterStarted?: boolean
  }
}

function isIOS() {
  return (
    /iPhone|iPad|iPod/.test(navigator.platform) ||
    /iPhone|iPad|iPod/.test(navigator.userAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  )
}

document.flutterStarted = false

addEventListener("unhandledrejection", (event) => {
  if (event.reason instanceof EvalError) {
    if (isIOS() && !document.flutterStarted) {
      document.write(`
        <div style="text-align: center; padding: 50px;">
          <h1>We're sorry, Aescape only supports iOS 16+</h1>
        </div>
      `)
    }
  }
})
